import { Controller } from "@hotwired/stimulus"

const validate =  require("validate.js")

validate.validators.file = function(value, options, key, attributes) {
  const inputs = document.getElementsByName(key)
  let input = null
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].type === "file") {
      input = inputs[i]
      break
    }
  }

  if (options.maxFiles && input.files.length > options.maxFiles) {
    return "has too many files selected for upload";
  }

  if (options.maxSize) {
    const files = input.files
    for (let x = 0; x < files.length; x++) {
      const fileSize = ((files[x].size/1024)/1024).toFixed(4); // MB
      if (fileSize > options.maxSize) {
        return "is too large";
      }
    }
  }

  if (options.video) {
    const files = input.files
    for (let x = 0; x < files.length; x++) {
      if (!files[x].type.match(/^video\/.*?$/)) {
        return "is not an acceptable file type";
      }
    }
  }
};

validate.formatters.brief = function(errors) {
  return errors.reduce((memo, error) => {
    memo[error.attribute] = [error.options.message || error.error]
    return memo
  }, {})
};

// Connects to data-controller="survey-validation"
export default class extends Controller {
  static outlets = ["errors"]

  validated = false

  connect() {
  }

  validate(event) {
    this.validated = true
    if (!this.doValidate()) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  revalidate() {
    if (this.validated) {
      this.doValidate()
    }
  }

  doValidate() {
    this.errorsOutlet.clearErrors()
    const values = validate.collectFormValues(this.element)
    // console.log("values", values)
    const errors = validate(values, this.buildValidationConstraints(), {format: "brief"})

    if (errors) {
      // console.log(errors);
      Object.keys(errors).forEach((key) => {
        this.errorsOutlet.showError(key, errors[key][0])
      })

      return false
    }

    return true
  }

  buildValidationConstraints() {
    const constraints = {}
    this.buildRateQuestionConstraints(constraints)
    this.buildTextQuestionConstraints(constraints)
    this.buildVideoQuestionConstraints(constraints)
    return constraints
  }

  buildRateQuestionConstraints(constraints) {
    const ratingInputs = this.element.querySelectorAll("input[data-rating-input-target=rating]")
    ratingInputs.forEach((input) => {
      constraints[input.getAttribute('name')] = {
        presence: true,
        numericality: {
          message: "Please leave a rating",
          greaterThan: 0,
          lessThanOrEqualTo: 5
        }
      }
    })
  }

  buildTextQuestionConstraints(constraints) {
    const textInputs = this.element.querySelectorAll("textarea")
    textInputs.forEach((input) => {
      constraints[input.getAttribute('name')] = {
        presence: {
          message: "Please leave a answer"
        },
      }
    })
  }

  buildVideoQuestionConstraints(constraints) {
    const videoInputs = this.element.querySelectorAll("input[data-video-upload-target=videoField]")
    videoInputs.forEach((input) => {
      const thumbs = input.parentElement.querySelector("div[data-video-upload-target='videoThumbnail']")
      const numUploadedVideos = thumbs.querySelectorAll("div[data-video-uploaded='true']").length

      let validations = {
        file: {
          message: "Up to 3 videos, each less than 500MB in size.",
          video: true,
          maxFiles: 3,
          maxSize: 500
        }
      }
      if (numUploadedVideos == 0) {
        validations["presence"] = {
          message: "Please upload a video",
        }
      }

      validations["file"] = {
        message: "Up to 3 videos, each less than 500MB in size.",
        video: true,
        maxFiles: 3 - numUploadedVideos,
        maxSize: 500
      }

      constraints[input.getAttribute('name')] = validations
    })
  }
}
