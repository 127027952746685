import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visit"
export default class extends Controller {
  static values = {
    url: String,
    options: Object
  }

  connect() {
  }

  visit(event) {
    if (!this.urlValue) {
      console.error('URL value is missing')
      return
    }
    Turbo.visit(this.urlValue, this.optionsValue || {}) 
  }
}
