import RootsController from './roots_controller'
/*
    This controller allows you listen to click events on radio/check elements and disable associated
    controller target items by changing their state to disabled (or enabled) and assigning/removing
    corresponding classes, if any. E.g.

        <div data-controller="enables">
           <input data-enables-target="source" data-enables-mode="checked" data-action="click->enables#refresh" ... />
           <button type=submit
                data-enables-target="item"
                data-enables-enabled-class="bg-roots-green-500"
                data-enables-disabled-class="bg-grey-300"
                class="...">
                 Button Text
           </button>
        </div>
 */


function classArray(string) {
    var result = string.trim().split(/\s+/).filter(word => word.length > 0)
    return result
}

export default class extends RootsController {
    static targets = ['item', 'source']

    connect (){
        this.debug_log(`connected`)
        this.refresh()
    }

    refresh() {
        const disabled = !!this.sourceTargets.find((src) => !this._elementEnabled(src));
        this.itemTargets.forEach(item => {
            item.disabled = disabled

            let enabledClass = item.dataset.enablesEnabledClass
            let disabledClass = item.dataset.enablesDisabledClass

            if (disabled) {
                this._toggleClasses(item, disabledClass, enabledClass)
            } else {
                this._toggleClasses(item, enabledClass, disabledClass)
            }
        })
    }

    _toggleClasses(item, add, remove) {
        this.debug_log(`item.name: "${item.id || item.name}" disabled: ${item.disabled} adding: "${ add || ''}" removing: "${remove||''}"`)

        if(remove) classArray(remove).forEach(clazz => item.classList.remove(clazz) )
        if(add) classArray(add).forEach(clazz => item.classList.add(clazz) )
    }

    _elementEnabled(src) {
        let mode = src.dataset['enablesMode'] || 'checked' // 'active' requires source be be checked and enabled. Default only 'checked'
        let value = null
        if (mode === 'active') {
            value = src.checked && !src.disabled
        } else if (mode === 'presence') {
            value = !!src.value.trim().length
        } else {
            value = src.checked
        }

        this.debug_log(`source id: "${src.id || src.name}" mode: "${mode}" check: ${src.checked} disabled: ${src.disabled} value: ${value}`)

        return value
    }
}
