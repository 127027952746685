import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["player", "cover"];

    connect() {
        this.hideControls();

        this.boundShowControls = this.showControls.bind(this);
        this.playerTarget.addEventListener("playing", this.boundShowControls);
    }

    hideControls() {
        this.playerTarget.controls = false;
    };

    showControls() {
        this.playerTarget.controls = true;
    };

    playPause() {
        if (this.playerTarget.paused) {
            if (this.hasCoverTarget) {
                this.coverTarget.classList.add("hidden")
            }
            return this.playerTarget.play()
        }

        this.playerTarget.pause()
    }
}
