import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="view-group"
export default class extends Controller {
  static targets = [
    "view"
  ]

  static values = {
    initialGroup: String,
    groupsOrder: Array
  }

  group = null
  groupsOrder = []

  connect() {
    this.group = this.initialGroupValue
    this.updateViews()
  }

  viewTargetConnected(el) {
    const groupName = el.dataset.groupName
    this.groupsOrder = this.groupsOrder || this.groupsOrderValue || []
    if (this.groupsOrder.indexOf(groupName) < 0) {
      this.groupsOrder.push(groupName)
    }
  }

  updateViews() {
    this.viewTargets.forEach((el) => {
      if (this.group === el.dataset.groupName) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })
  }

  toggle = () => {
    let index = (this.groupsOrder.indexOf(this.group) + 1) % this.groupsOrder.length
    this.group = this.groupsOrder[index]
    this.updateViews()
  }
}
