import { Controller } from "@hotwired/stimulus"
import { destroy } from "@rails/request.js"

function filterMatch(v1, v2) {
  return v2.toLowerCase().includes(v1.toLowerCase())
}

class CategoryWrapper {
  constructor(accordion, categoryTarget) {
    this.accordion = accordion
    this.categoryTarget = categoryTarget
    this.body = document.querySelector(`[aria-labelledby="${categoryTarget.id}"]`)
    this.items = Array.from(this.body.getElementsByTagName("li"))
  }

  unfilter() {
    this.categoryTarget.classList.remove("hidden")
    this.items.forEach((item) => item.classList.remove("hidden"))
  }

  setHidden(item, hidden) {
    if (hidden) {
      item.classList.add("hidden")
    } else {
      item.classList.remove("hidden")
    }
  }

  open(selector) {
    if (this.body.classList.contains("hidden")) {
      this.accordion.open(`#${this.body.id}`)
    }
  }

  close(selector) {
    if (!this.body.classList.contains("hidden")) {
      this.accordion.close(`#${this.body.id}`)
    }
  }
}

// Connects to data-controller="inspection-question"
export default class extends Controller {
  static outlets = [ "flowbite-modal" ]
  static targets = [ "toggle", "category", "item" ]

  filtering = false

  get accordion() {
    return FlowbiteInstances.getInstance('Accordion', 'inspection-accordion')
  }

  connect() {
  }

  filter(event) {
    const value = event.currentTarget.value.toLowerCase()

    if (value.length) {
      this.filtering = true
    } else {
      if (this.filtering) {
        this.categoryTargets.forEach((category) => {
          const wrap = new CategoryWrapper(this.accordion, category)
          wrap.unfilter()
          wrap.close()
        })
      }
      this.filtering = false
      return
    }

    this.categoryTargets.forEach((category) => {
      const wrap = new CategoryWrapper(this.accordion, category)

      if (filterMatch(value, category.dataset.name)) {
        wrap.unfilter()

      } else {
        const matches = wrap.items.filter((item) => filterMatch(value, item.dataset.name))

        // if there are any item matches, we open the category and filter to show only the matches
        if (matches.length) {
          wrap.items.forEach((item) => wrap.setHidden(item, !matches.includes(item)))
          wrap.setHidden(category, false)
          wrap.open()

        } else {
          wrap.unfilter()
          wrap.setHidden(category, true)
          wrap.close()
        }
      }
    })
  }

  toggle(event) {
    const item = event.currentTarget.closest("li")
    if (item.dataset.issues == "true") {
      this.itemId = item.id
      this.destroyUrl = event.params.destroyUrl
      this.flowbiteModalOutlet.show()
    } else {
      Turbo.visit(event.params.editUrl, { action: "replace", frame: "remote_modal" })
    }
  }

  revertIssue(_event) {
    this.flowbiteModalOutlet.hide()
    const issues = document.getElementById(this.itemId).dataset.issues == "true"
    document.querySelector(`#${this.itemId} input[type=checkbox]`).checked = issues ? true : false
    delete this.itemId
    delete this.destroyUrl
  }

  editIssue(event) {
    Turbo.visit(event.params.url, { action: "replace", frame: "remote_modal" })
  }

  removeIssue(_event) {
    this.flowbiteModalOutlet.hide()
    destroy(this.destroyUrl, { responseKind: "turbo-stream" })
    delete this.itemId
    delete this.destroyUrl
  }

  showCategory(category) {
    category.classList.remove("hidden")
    items.forEach((item) => item.classList.remove("hidden"))
    this.accordion.close(`#${body.id}`)
  }

  exitIssue(event) {
    const { modalId, id } = event.detail
    if (modalId === "inspection-modal") {
      const issues = document.getElementById(`inspection_item_${id}`).dataset.issues == "true"
      document.querySelector(`#inspection_item_${id} input[type=checkbox]`).checked = issues ? true : false
    }
  }
}
